import React from 'react';

const StaffAugmentationSvg = () => (
  <svg viewBox="0 0 1105 265">
    <g id="Group_1236" data="Group 1236" transform="translate(-216 -465)">
      <text id="recruiting_paperwork" data="recruiting paperwork" transform="translate(848 550)" fill="#78828a" fontSize="23" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="25">recruiting paperwork</tspan></text>
      <text id="payroll_taxes" data="payroll taxes" transform="translate(227 557)" fill="#78828a" fontSize="23" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="25">payroll taxes</tspan></text>
      <text id="invest_extra_time_for_ramp-up_training" data="invest extra time for ramp-up training" transform="translate(586 656)" fill="#78828a" fontSize="23" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700">
        <tspan x="0" y="25">invest extra time </tspan>
        <tspan x="0" y="56">for ramp-up </tspan>
      </text>
      <text id="prepare_job_advertisements" data="prepare job advertisements" transform="translate(548 544)" fill="#4eba89" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="22">prepare job advertisements</tspan></text>
      <text id="review_applications" data="review applications" transform="translate(398 599)" fill="#4eba89" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="22">review applications</tspan></text>
      <text id="perform_interviews" data="perform interviews" transform="translate(812 687)" fill="#4eba89" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="22">perform interviews</tspan></text>
      <text id="test_candidates" data="test candidates" transform="translate(983 640)" fill="#4eba89" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="22">test candidates</tspan></text>
      <text id="check_for_references_" data="check for references" transform="translate(276 667)" fill="#4eba89" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="22">check for references</tspan></text>
      <text id="You_don_t_need_to_worry_of_any_of_these_anymore._That_s_our_job_" data="You don&apos;t need to worry of any of these anymore. That&apos;s our job! " transform="translate(216 504)" fill="#9488c4" fontSize="36" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="0">You don&apos;t need to worry of any of these anymore. That&apos;s our job! </tspan></text>
    </g>
  </svg>
);

export default StaffAugmentationSvg;
