import React from 'react';
import { Typography } from '@material-ui/core';
import './styles.css';

const TextListItem = ({ texts }) => (
  <>
    <div className="text-item-container">
      {
          texts.map((message, i) => (
            <Typography variant="h6" component="h3" key={i}>
              {message}
            </Typography>
          ))
        }
    </div>
  </>
);

export default TextListItem;
