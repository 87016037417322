import React from 'react';
import { Grid } from '@material-ui/core';
import StaffBenefitItemText from './StaffBenefitItemText';
import StaffBenefitItemTextComponse from './StaffBenefitItemTextComponse';
import './styles.css';

const compareSelectedValueMemo = (prevProp, nextProp) => {
  const { selected: selectedPrevious } = prevProp;
  const { selected: selectedNext } = nextProp;
  if (selectedPrevious === selectedNext) {
    return true;
  }
  return false;
};

const StaffBenefitItem = ({
  currentContent, clickedContent, xs, sm, selected, index, benefitClicked,
}) => {
  const onClickBenefitItem = () => {
    if (!selected) {
      benefitClicked({ index });
    }
  };

  return (
    <Grid item xs={xs} sm={sm} className="staff-benefit-item" onClick={onClickBenefitItem}>
      {
        selected ? (
          <StaffBenefitItemText
            clickedContent={clickedContent}
          />
        ) : <StaffBenefitItemTextComponse content={currentContent} />
      }
    </Grid>
  );
};

export default React.memo(StaffBenefitItem,
  (prevProp, nextProp) => compareSelectedValueMemo(prevProp, nextProp));
