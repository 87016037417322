import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid, Typography, Paper, Fab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Link from 'gatsby-link';
import { STAFF_AUGMENTATION_LIST } from '../helpers/StaffAugmentationHelper';
import { StaffAugmentationSvg } from '../assets/svg/svg-clock';
import StaffBenefitList from '../components/StaffAugmentationPages';
import { SEO } from '../shared/seo';
import Header from '../layout/Header/header';
import Footer from '../layout/Footer/footer';

import '../styles/staffAugmentationStyles.css';

const overrides = {
  title: 'Staff Augmentation | Nearshore Code',
  description: 'We help you add skilled resources to your in-house development team, taking charge of the whole employment process.',
  openGraph: {
    type: 'website',
    url: 'https://nearshorecode.com/staff-augmentation',
    title: 'Staff Augmentation | Nearshore Code',
    description: 'We help you add skilled resources to your in-house development team, taking charge of the whole employment process.',
    image: 'https://nearshorecode.com/facebookimage.png',
  },
  twitter: {
    card: 'summary_large_image',
    url: 'https://nearshorecode.com/staff-augmentation',
    title: 'Staff Augmentation | Nearshore Code',
    description: 'We help you add skilled resources to your in-house development team, taking charge of the whole employment process.',
    image: 'https://nearshorecode.com/twitterimage.png',
  },
};

export const StaffAugmentation = ({ clicked }) => {
  const [staffListBenefit, setStaffListBenefit] = useState(STAFF_AUGMENTATION_LIST);

  const onClickStaffAugmentation = useCallback(({ index }) => {
    const settedBenefits = staffListBenefit.map((benefit, i) => {
      if (index === i) {
        return { ...benefit, selected: true };
      }
      return { ...benefit, selected: false };
    });

    setStaffListBenefit(settedBenefits);
  }, [staffListBenefit]);

  useEffect(() => {
    let increment = 0;
    const benefitSize = STAFF_AUGMENTATION_LIST.length - 1;

    window.scrollTo({ top: 0 });

    const timer = setInterval(() => {
      if (increment < benefitSize) {
        increment += 1;
      } else {
        increment = 0;
      }
      onClickStaffAugmentation({ index: increment });
      return () => {
        clearTimeout(timer);
      };
    }, 3000);
  }, [clicked]);

  return (
    <div>
      <SEO overrides={overrides} />
      <Header />
      <div className="staff-augmentation-container">
        <div className="staff-augmentation-info">
          <Grid container className="staff-augmentation-content">
            <Grid item xs={12} className="staff-augmentation-title">
              <Typography align="center">Staff Augmentation</Typography>
            </Grid>
            <Grid item xs={12} className="staff-augmentation-description">
              <Typography className="paragraph1">Staff Augmentation is an outsourcing strategy that enables you to hire individual candidates that best fit your requirements. It's the smartest way to staff a project when you don’t have enough resources to meet a deadline or need personnel with particular skills to pursue immediate objectives.</Typography>
              <Typography className="paragraph2">As your provider, we help you add skilled resources to your in-house development team, taking charge of the whole employment process. You get rid of all the behind-the-scenes effort it takes to process the recruitment and handle employees.</Typography>
            </Grid>
            <Grid item xs={12}>
              <StaffAugmentationSvg />
            </Grid>
          </Grid>
        </div>
        <div className="staff-augmentation-benefits">
          <Grid container className="staff-augmentation-benefits-container">
            <Grid item xs={12} className="staff-augmentation-benefits-content">
              <StaffBenefitList
                benefits={staffListBenefit}
                benefitClicked={onClickStaffAugmentation}
              />
            </Grid>
          </Grid>
        </div>
        <div className="staff-augmentation-service">
          <Grid container className="staff-augmentation-service-container">
            <Paper className="staff-augmentation-service-paper">
              <Typography variant="body1" align="justify">
                Our service does not have a “one size fits all” approach as we realize that all companies have their own dynamics and needs. Therefore, we work together with our customers in designing the appropriate mix for the staffing service we provide them.
              </Typography>
            </Paper>
          </Grid>
        </div>
        <div className="slide-section-close">
          <Link to="/">
            <Fab className="slide-item-close" size="small" color="secondary" aria-label="close">
              <CloseIcon />
            </Fab>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(StaffAugmentation);
