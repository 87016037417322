import imgQualifiedEngineers from '../assets/qualified-engineers.png';
import imgBestInvestment from '../assets/best-investment.png';
import imgMeetingDeadlines from '../assets/meeting-deadlines.png';

const STAFF_AUGMENTATION_LIST = [
  {
    currentContent: {
      image: imgQualifiedEngineers,
      text: 'Immediate|acquisition of|qualified engineers ...',
    },
    clickedContent: 'You gain access to request specific skill sets for the flexible staff you will be provided.',
    xs: 12,
    sm: 4,
    selected: true,
  },
  {
    currentContent: {
      image: imgBestInvestment,
      text: 'Better investment of|time and money ...',
    },
    clickedContent: 'It\'s a cost-effective practice since you are relieved of the costs and liabilities that involve hiring direct employees.',
    selected: false,
    xs: 12,
    sm: 4,
  },
  {
    currentContent: {
      image: imgMeetingDeadlines,
      text: 'Beneficial when|facing deadlines ...',
    },
    clickedContent: 'Qualified human resources are ready-to-go and blend into the teams with very short or no ramp-up period.',
    selected: false,
    xs: 12,
    sm: 4,
  },
];

export {
  STAFF_AUGMENTATION_LIST,
};
