import React from 'react';
import { Grid } from '@material-ui/core';
import StaffBenefitItem from './StaffBenefitItem';
import './styles.css';

const StaffBenefitList = ({ benefits, benefitClicked }) => (
  <Grid container className="staff-benefit-list-content">
    {
        benefits.map(({
          currentContent, clickedContent, xs, sm, selected,
        }, i) => (
          <StaffBenefitItem
            currentContent={currentContent}
            clickedContent={clickedContent}
            xs={xs}
            sm={sm}
            selected={selected}
            benefitClicked={benefitClicked}
            index={i}
            key={i}
          />
        ))
      }
  </Grid>
);

export default React.memo(StaffBenefitList);
