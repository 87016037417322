import React from 'react';
import { Paper } from '@material-ui/core';
import TextListItem from '../StaffBenefitItemText/TextListItem';
import './styles.css';

const showTextParts = (title, separator) => title.split(separator);

export default ({ content: { image, text } }) => {
  const textParts = showTextParts(text, '|');

  return (
    <div className="staff-benefit-item-compose-container">
      <Paper className="staff-benefit-compose-paper">
        <div className="staff-benefit-compose-content">
          <div className="staff-benefit-img-container">
            <img src={image} alt="benefit" />
          </div>
          <TextListItem texts={textParts} />
        </div>
      </Paper>
    </div>
  );
};
