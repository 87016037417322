import React from 'react';
import { Paper } from '@material-ui/core';
import TextListItem from './TextListItem';
import './styles.css';

export default ({ clickedContent }) => (
  <Paper className="staff-benefit-item-text-container">
    <div className="staff-benefit-item-text-section">
      <div className="staff-benefit-item-text-content">
        <TextListItem texts={[clickedContent]} />
      </div>
    </div>
  </Paper>
);
